export default {
    name: "trainingService",
    data() {
        return {
            banner: '//24181030.s61i.faiusr.com/2/AD0IpvLDCxACGAAgn9349gUooMn_5gIwgA84rAI.jpg',
            intro: [
                {
                    eng: 'OUT TOTAL SOLUTION',
                    title: '我们的解决方案',
                },{
                    eng: 'WHY WE ARE DIFFERENT',
                    title: '我们的不同',
                }
            ],
            contents: [
                {
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-1.png',
                    title: '挑战与困难',
                    desc: '财税领域政策频繁变化，能力需要持续更新<br/>' +
                        '线下课程成本高，占用时间多，培训效果存疑<br/>' +
                        '员工学习的进度与效果无法量化，难以管理考核<br/>' +
                        '财税课程内容专业，系统化并保持更新需要大量的人力物力'
                },{
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-2.png',
                    title: '解决方案',
                    desc: '培训服务、工具平台、强大的内容库工具库三位一体<br/>' +
                        '财税专业训练课程、课件制作、专业讲师<br/>' +
                        '可管理、可监督、可量化评估的内训与学习平台<br/>' +
                        '完善的财税政策法规库、问答库、课程库及专业工具'

                },{
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-3.png',
                    title: '培训计划',
                    desc: '财税管理/服务领域新员工入职基础培训<br/>' +
                        '近期培训计划：最新财税热点、政策解读、操作指南<br/>' +
                        '中期培训计划：覆盖财税实操细节，全流程财税实操训练<br/>' +
                        '长期培训计划：财务税法系统培训，巩固专业理论，理解核心逻辑'
                },{
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-4.png',
                    title: '平台与工具',
                    desc: '手机小程序端与PC Web端任务式训练平台与工具箱<br/>' +
                        '可监督、可管理的学习过程，可量化的学习效果<br/>' +
                        '灵活的各类辅助学习工具，强大的学习搜索与辅助工具库<br/>' +
                        '完善的后台管理工具，掌握、评估、分析学员的学习情况'
                }
            ],
            teachers: [
                {
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-5-1.png',
                    name: '不仅是软件，更是服务',
                    engName: '',
                    desc: '我们对训练全程负责，您只需看效果'
                },{
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-5-2.png',
                    name: '强大、免费的工具平台',
                    engName: '',
                    desc: '针对财税行业研发的可监督的学习训练平台'
                },{
                    img: 'http://public.wencaishui.com/images/learning/pc/solution-5-4.png',
                    name: '专业、丰富的内容储备',
                    engName: '',
                    desc: '丰富、强大、不断更新的知识与训练内容储备'
                }
            ]
        }
    }
}
